/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

import './infected-cell.css';

const validHighlightNames = [
  'cytoplasm',
  'ER',
  'Golgi',
  'mitochondrion',
  'nucleus',
];

const getHighlightName = (highlighted) => {
  if (highlighted.cell) {
    return highlighted.cell.replace('_', ' ');
  }
  if (highlighted.protein && validHighlightNames.includes(highlighted.protein)) {
    return highlighted.protein.replace('_', ' ');
  }
  return '';
};

const InfectedCell = ({
  handleGraphicMouseOut,
  handleGraphicMouseOver,
  highlighted,
}) => (
  <div className="protein-nav__cell">
    <svg
      data-highlighted={highlighted.cell}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
    >
      <g
        data-localization="cytoplasm"
        onBlur={handleGraphicMouseOut}
        onFocus={handleGraphicMouseOver}
        onMouseOut={handleGraphicMouseOut}
        onMouseOver={handleGraphicMouseOver}
      >
        <path className="protein-nav__cell_light" d="M199.6 49.8c-26 0-50.4 6.6-71.7 18.2 7.4 6.9 12.1 16.7 12.1 27.5 0 20.7-16.8 37.5-37.5 37.5-11.8 0-22.4-5.5-29.3-14.1-15 23.3-23.6 51.1-23.6 80.8 0 82.8 67.2 150 150 150s150-67.2 150-150-67.2-149.9-150-149.9z" />
      </g>
      <g data-localization="plasma_membrane">
        <path className="protein-nav__cell_dark" d="M199.6 63.8c36.3 0 70.5 14.1 96.2 39.8 25.7 25.7 39.8 59.8 39.8 96.2s-14.1 70.5-39.8 96.2c-25.7 25.7-59.8 39.8-96.2 39.8s-70.5-14.1-96.2-39.8c-25.7-25.7-39.8-59.8-39.8-96.2 0-20.7 4.7-41 13.6-59.4 7.6 4.3 16.3 6.6 25.3 6.6 28.4 0 51.5-23.1 51.5-51.5 0-7.7-1.7-15.2-5-22 16-6.4 33.2-9.7 50.6-9.7m0-14c-26 0-50.4 6.6-71.7 18.2 7.4 6.9 12.1 16.7 12.1 27.5 0 20.7-16.8 37.5-37.5 37.5-11.8 0-22.4-5.5-29.3-14.1-15 23.3-23.6 51.1-23.6 80.8 0 82.8 67.2 150 150 150s150-67.2 150-150-67.2-149.9-150-149.9z" />
      </g>
      <g id="virus">
        <g>
          <circle className="protein-nav__cell-virus_light" cx="101.8" cy="93.7" r="28" />
        </g>
        <path className="protein-nav__cell-virus_dark" d="M101.8 69.7c13.2 0 24 10.8 24 24s-10.8 24-24 24-24-10.8-24-24 10.8-24 24-24m0-4c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28z" />
        <g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M101.8 67.2c-.8 0-1.5-.7-1.5-1.5v-4.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v4.5c0 .8-.6 1.5-1.5 1.5z" />
            <path className="protein-nav__cell-virus_dark" d="M104.1 62.7h-4.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h4.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M87.8 70.9c-.5 0-1-.3-1.3-.8l-2.2-3.9c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l2.2 3.9c.4.7.2 1.6-.5 2-.2.3-.4.3-.7.3z" />
            <path className="protein-nav__cell-virus_dark" d="M83.7 68.2c-.5 0-1-.3-1.3-.8-.4-.7-.2-1.6.5-2l3.9-2.2c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2L84.4 68c-.2.1-.5.2-.7.2z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M77.6 81.2c-.3 0-.5-.1-.7-.2L73 78.8c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l3.9 2.2c.7.4 1 1.3.5 2-.3.4-.8.7-1.3.7z" />
            <path className="protein-nav__cell-virus_dark" d="M72.6 80.9c-.3 0-.5-.1-.7-.2-.7-.4-1-1.3-.5-2l2.2-3.9c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-2.2 3.9c-.3.4-.8.7-1.3.7z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M73.8 95.2h-4.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h4.5c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z" />
            <path className="protein-nav__cell-virus_dark" d="M69.3 97.4c-.8 0-1.5-.7-1.5-1.5v-4.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v4.5c0 .9-.6 1.5-1.5 1.5z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M73.7 111.5c-.5 0-1-.3-1.3-.8-.4-.7-.2-1.6.5-2l3.9-2.2c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-3.9 2.2c-.2.2-.4.3-.7.3z" />
            <path className="protein-nav__cell-virus_dark" d="M74.8 113.4c-.5 0-1-.3-1.3-.8l-2.2-3.9c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l2.2 3.9c.4.7.2 1.6-.5 2-.2.2-.4.3-.7.3z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M85.6 123.3c-.3 0-.5-.1-.7-.2-.7-.4-1-1.3-.5-2l2.2-3.9c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-2.2 3.9c-.3.5-.8.7-1.3.7z" />
            <path className="protein-nav__cell-virus_dark" d="M87.5 124.5c-.3 0-.5-.1-.7-.2l-3.9-2.2c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l3.9 2.2c.7.4 1 1.3.5 2-.2.4-.7.7-1.3.7z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M101.8 127.7c-.8 0-1.5-.7-1.5-1.5v-4.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v4.5c0 .8-.6 1.5-1.5 1.5z" />
            <path className="protein-nav__cell-virus_dark" d="M104.1 127.7h-4.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h4.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M118.1 123.3c-.5 0-1-.3-1.3-.8l-2.2-3.9c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l2.2 3.9c.4.7.2 1.6-.5 2-.2.3-.4.3-.7.3z" />
            <path className="protein-nav__cell-virus_dark" d="M116.2 124.5c-.5 0-1-.3-1.3-.8-.4-.7-.2-1.6.5-2l3.9-2.2c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-3.9 2.2c-.2.2-.5.3-.7.3z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M130 111.5c-.3 0-.5-.1-.7-.2l-3.9-2.2c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l3.9 2.2c.7.4 1 1.3.5 2-.3.4-.8.7-1.3.7z" />
            <path className="protein-nav__cell-virus_dark" d="M128.9 113.4c-.3 0-.5-.1-.7-.2-.7-.4-1-1.3-.5-2l2.2-3.9c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-2.2 3.9c-.3.4-.8.7-1.3.7z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M134.3 95.2h-4.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h4.5c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z" />
            <path className="protein-nav__cell-virus_dark" d="M134.3 97.4c-.8 0-1.5-.7-1.5-1.5v-4.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v4.5c0 .9-.6 1.5-1.5 1.5z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M126.1 81.2c-.5 0-1-.3-1.3-.8-.4-.7-.2-1.6.5-2l3.9-2.2c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-3.9 2.2c-.2.2-.4.3-.7.3z" />
            <path className="protein-nav__cell-virus_dark" d="M131.1 80.9c-.5 0-1-.3-1.3-.8l-2.2-3.9c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l2.2 3.9c.4.7.2 1.6-.5 2-.2.2-.4.3-.7.3z" />
          </g>
          <g>
            <path className="protein-nav__cell-virus_dark" d="M115.9 70.9c-.3 0-.5-.1-.7-.2-.7-.4-1-1.3-.5-2l2.2-3.9c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-2.2 3.9c-.3.5-.8.7-1.3.7z" />
            <path className="protein-nav__cell-virus_dark" d="M120 68.2c-.3 0-.5-.1-.7-.2l-3.9-2.2c-.7-.4-1-1.3-.5-2s1.3-1 2-.5l3.9 2.2c.7.4 1 1.3.5 2-.2.4-.7.7-1.3.7z" />
          </g>
        </g>
        <g>
          <circle className="protein-nav__cell-virus_medium" cx="79.6" cy="93.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="79.9" cy="97.2" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="80.7" cy="100.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="82.1" cy="103.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="83.9" cy="106.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="86.1" cy="109.4" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="88.8" cy="111.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="91.8" cy="113.5" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="95" cy="114.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="98.4" cy="115.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="101.8" cy="115.9" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="105.3" cy="115.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="108.7" cy="114.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="111.9" cy="113.5" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="114.9" cy="111.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="117.5" cy="109.4" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="119.8" cy="106.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="121.6" cy="103.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="123" cy="100.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="123.8" cy="97.2" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="124.1" cy="93.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="123.8" cy="90.2" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="123" cy="86.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="121.6" cy="83.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="119.8" cy="80.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="117.5" cy="78" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="114.9" cy="75.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="111.9" cy="73.9" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="108.7" cy="72.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="105.3" cy="71.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="101.8" cy="71.5" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="98.4" cy="71.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="95" cy="72.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="91.8" cy="73.9" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="88.8" cy="75.7" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="86.1" cy="78" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="83.9" cy="80.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="82.1" cy="83.6" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="80.7" cy="86.8" r="1.8" />
          <circle className="protein-nav__cell-virus_medium" cx="79.9" cy="90.2" r="1.8" />
        </g>
        <path className="protein-nav__virus_medium protein-nav__cell-virus_semi-trans" d="M107.7 79.8H96l-5.8 13.9 5.8 13.9h11.7l5.8-13.9z" />
        <path className="protein-nav__cell-virus_dark" d="M107.7 108.6H96c-.4 0-.8-.2-.9-.6l-5.8-13.9c-.1-.2-.1-.5 0-.8l5.8-13.9c.2-.4.5-.6.9-.6h11.7c.4 0 .8.2.9.6l5.8 13.9c.1.2.1.5 0 .8l-5.8 13.9c-.2.4-.5.6-.9.6zm-11-2H107l5.4-12.9-5.4-12.9H96.7l-5.4 12.9 5.4 12.9z" />
        <path className="protein-nav__cell-virus_dark" d="M105.7 83.7c-1.3-.5-2.6-.9-4-1.1-1.2-.2-2.4-.3-3.4.5-1.6 1.2-1.6 3.5-.3 4.9 1.7 1.8 4.3 1.7 6.5 1.2.3-.1 1.6-.5 1.8-.1.2.4-.2 1-.5 1.2-.3.2-.7.4-1.1.5-1.4.5-2.9.7-4.4.6-1.1-.1-2.4-.8-3.5-.4-.9.3-1.4 1.3-1.6 2.2-.2 1.8.8 3.6 2.5 4.2 2.4.9 5.1-.9 7.4-1.6.1 0 .3-.1.4-.1.4-.1.5-.1.3-.1.1.3-.2.9-.4 1.1-.4.5-.9.9-1.5 1.2-1.4.7-2.8 1.2-4.3 1.7-.9.3-1.9.7-2.5 1.5-.5.9-.3 1.9.5 2.5 2.2 1.9 5.5-1 7.6 1.4.6.7 1.7-.3 1.1-1.1-1.3-1.5-3.3-1.6-5.1-1.4-.7.1-1.5.3-2.1.1-.8-.3-.7-.9-.1-1.3 1-.6 2.2-.8 3.2-1.2 1.9-.7 4.3-1.6 4.9-3.7.2-.7.3-1.8-.4-2.3-.8-.6-2.1.1-3 .4-1.9.6-5.1 2.6-6.6.5-.4-.6-.8-1.8-.2-2.4.5-.5 1.6 0 2.2.1.6.1 1.2.2 1.9.2 2 0 4.9-.4 6.3-2 1.3-1.5.3-3.5-1.6-3.5-1.2 0-2.3.6-3.5.6-1.3 0-3-.4-3.5-1.8-.6-1.5.8-2.4 2.2-2.3 1.5.1 3.1.6 4.5 1.2.8.4 1.2-1 .3-1.4z" />
      </g>
      <g
        data-localization="ER"
        onBlur={handleGraphicMouseOut}
        onFocus={handleGraphicMouseOver}
        onMouseOut={handleGraphicMouseOut}
        onMouseOver={handleGraphicMouseOver}
      >
        <path
          className="protein-nav__cell_dark"
          d="M203,304.5c-0.9,0-1.8-0.3-2.5-1c-1.4-1.4-1.4-3.6,0-4.9c20.7-20.7,20.7-54.5,0-75.2
          c-10-10-23.4-15.6-37.6-15.6s-27.6,5.5-37.6,15.6c-1.4,1.4-3.6,1.4-5,0c-1.4-1.4-1.4-3.6,0-4.9c11.4-11.4,26.5-17.6,42.6-17.6
          c16.1,0,31.2,6.3,42.6,17.6c23.5,23.5,23.5,61.7,0,85.1C204.8,304.1,203.9,304.5,203,304.5z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M214,307.5c-0.9,0-1.8-0.3-2.5-1c-1.4-1.4-1.4-3.6,0-4.9c10.8-10.8,16.3-27.2,14.7-43.9
          c-0.2-1.9,1.2-3.6,3.2-3.8c1.9-0.2,3.6,1.2,3.8,3.2c1.7,18.8-4.6,37.3-16.8,49.5C215.8,307.2,214.9,307.5,214,307.5z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M225.3,242.3c-1.4,0-2.7-0.8-3.3-2.2c-3.4-8.6-8.5-16.4-15.3-23.1c-8.7-8.7-19.4-14.9-30.9-17.8
          c-1.9-0.5-3-2.4-2.5-4.3c0.5-1.9,2.4-3,4.3-2.5c12.7,3.2,24.6,10,34.2,19.6c7.4,7.4,13.1,16,16.8,25.5c0.7,1.8-0.2,3.8-2,4.5
          C226.2,242.3,225.7,242.3,225.3,242.3z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M120.8,212.6c-1,0-1.9-0.4-2.6-1.2c-1.3-1.5-1.1-3.7,0.3-4.9c10.8-9.5,26.9-14.8,44.1-14.7
          c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-0.2,0-0.4,0-0.5,0c-15.3,0-29.5,4.7-39,13C122.5,212.3,121.7,212.6,120.8,212.6z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M237.6,283.7c-0.3,0-0.7,0-1-0.1c-1.9-0.5-2.9-2.5-2.4-4.3c6.6-22.6-1.6-49.4-20.8-68.2c-3-3-6.3-5.7-9.7-8.2
          c-1.6-1.1-1.9-3.3-0.8-4.9c1.1-1.6,3.3-1.9,4.9-0.8c3.7,2.7,7.2,5.6,10.5,8.8c21,20.6,29.9,50.1,22.6,75.1
          C240.5,282.7,239.1,283.7,237.6,283.7z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M130,196.1c-1.3,0-2.6-0.8-3.2-2.1c-0.8-1.8,0-3.8,1.7-4.6c21.7-9.9,43.2-10.3,64.1-1.5
          c1.8,0.8,2.6,2.8,1.8,4.6c-0.8,1.8-2.8,2.6-4.6,1.8c-19-8.1-38.6-7.6-58.4,1.4C131,196,130.5,196.1,130,196.1z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M151.9,199.1c-1.5,0-2.8-1.1-3-2.6l-1.2-9.5c-0.2-1.6,1-3.1,2.6-3.3c1.6-0.2,3.1,1,3.3,2.6l1.2,9.5
          c0.2,1.6-1,3.1-2.6,3.3C152.2,199.1,152,199.1,151.9,199.1z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M136.4,213.7c-1.2,0-2.3-0.7-2.8-1.8l-3.6-8.4c-0.7-1.5,0.1-3.3,1.6-3.9c1.5-0.7,3.3,0.1,3.9,1.6l3.6,8.4
          c0.7,1.5-0.1,3.3-1.6,3.9C137.2,213.6,136.8,213.7,136.4,213.7z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M183,200.6c-0.4,0-0.9-0.1-1.3-0.3c-1.5-0.7-2.1-2.5-1.4-4l3.5-7.2c0.7-1.5,2.5-2.1,4-1.4
          c1.5,0.7,2.1,2.5,1.4,4l-3.5,7.2C185.2,200,184.1,200.6,183,200.6z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M196.6,216.5c-0.6,0-1.2-0.2-1.7-0.5c-1.4-0.9-1.7-2.8-0.8-4.2l3.8-5.6c0.9-1.4,2.8-1.7,4.2-0.8
          c1.4,0.9,1.7,2.8,0.8,4.2l-3.8,5.6C198.5,216.1,197.5,216.5,196.6,216.5z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M219.2,228.8c-0.9,0-1.7-0.4-2.3-1.1c-1.1-1.3-0.9-3.2,0.4-4.2l6.2-5.1c1.3-1.1,3.2-0.9,4.2,0.4
          c1.1,1.3,0.9,3.2-0.4,4.2l-6.2,5.1C220.6,228.6,219.9,228.8,219.2,228.8z"
        />
        <path className="protein-nav__cell_dark" d="M168.6,216.1c-1.7,0-3-1.3-3-3v-7.2c0-1.7,1.3-3,3-3s3,1.3,3,3v7.2C171.6,214.8,170.3,216.1,168.6,216.1z" />
        <path
          className="protein-nav__cell_dark"
          d="M219.9,296.1c-0.5,0-0.9-0.1-1.3-0.3l-7.2-3.6c-1.5-0.7-2.1-2.5-1.3-4c0.7-1.5,2.5-2.1,4-1.3l7.2,3.6
          c1.5,0.7,2.1,2.5,1.3,4C222.1,295.5,221,296.1,219.9,296.1z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M228.3,267.9c-0.2,0-0.3,0-0.5,0l-8.4-1.2c-1.9-0.3-3.2-2-3-4c0.3-1.9,2-3.3,4-3l8.4,1.2c1.9,0.3,3.2,2,3,4
          C231.5,266.7,230,267.9,228.3,267.9z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M217.6,278.2c-0.1,0-0.3,0-0.4,0l-8.4-1.2c-1.6-0.2-2.8-1.8-2.5-3.4c0.2-1.6,1.8-2.8,3.4-2.5l8.4,1.2
          c1.6,0.2,2.8,1.8,2.5,3.4C220.3,277.1,219,278.2,217.6,278.2z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M208,244.8c-1,0-2-0.5-2.6-1.5c-0.9-1.4-0.4-3.3,1-4.1l6-3.6c1.4-0.9,3.3-0.4,4.1,1c0.9,1.4,0.4,3.3-1,4.1
          l-6,3.6C209.1,244.6,208.5,244.8,208,244.8z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M238.8,277c-0.1,0-0.1,0-0.2,0l-9-0.6c-1.7-0.1-2.9-1.5-2.8-3.2c0.1-1.7,1.6-2.9,3.2-2.8l9,0.6
          c1.7,0.1,2.9,1.5,2.8,3.2C241.6,275.8,240.3,277,238.8,277z"
        />
      </g>
      <g
        data-localization="nucleus"
        onBlur={handleGraphicMouseOut}
        onFocus={handleGraphicMouseOver}
        onMouseOut={handleGraphicMouseOut}
        onMouseOver={handleGraphicMouseOver}
      >
        <circle className="protein-nav__cell_medium" cx="161.7" cy="261.8" r="47.7" />
        <path
          className="protein-nav__cell_dark"
          d="M161.7,313c-28.3,0-51.2-23-51.2-51.2s23-51.2,51.2-51.2s51.2,23,51.2,51.2S190,313,161.7,313z M161.7,217.5
          c-24.4,0-44.2,19.8-44.2,44.2s19.8,44.2,44.2,44.2s44.2-19.8,44.2-44.2S186.1,217.5,161.7,217.5z"
        />
      </g>
      <g
        data-localization="Golgi"
        onBlur={handleGraphicMouseOut}
        onFocus={handleGraphicMouseOver}
        onMouseOut={handleGraphicMouseOut}
        onMouseOver={handleGraphicMouseOver}
      >
        <path
          className="protein-nav__cell_dark"
          d="M233.8,127.8c1.4-0.2,2.1-1.5,2-2.6c-0.1-0.6-0.5-1.5-0.8-2.1c-0.3-0.5-1-1.1-1.5-1.4
          c-1.3-0.8-3-0.6-4.3-0.7c-1.6-0.2-3.3-0.6-4.9-1.4c-2.1-0.9-4.1-1.9-6-3.2c-1.9-1.3-3.5-2.9-4.8-4.6c-1.4-1.9-2.4-3.9-3.1-6
          c-0.3-0.8-0.6-1.7-1.1-2.5c-0.5-0.8-1.2-1.3-2-1.8c-1-0.7-2.1-0.8-3-0.4c-0.9,0.5-1.8,1.8-1.5,3.2c0.3,1.6,1.5,3,2.6,4.1
          c1.8,1.7,3.6,3.2,5.4,4.9c2.1,2,4.2,4.1,6.6,5.9c1.8,1.3,3.7,2.5,5.6,3.6c0.9,0.6,1.9,1.1,2.8,1.7c0.9,0.6,1.7,1.3,2.6,1.8
          c0.8,0.5,1.8,1,2.9,1.3C232.1,127.8,233,127.9,233.8,127.8z M204.1,100.7c1-0.5,2.2-0.3,3.3,0.4c0.9,0.6,1.6,1.2,2.1,1.9
          c0.5,0.8,0.8,1.6,1.1,2.4l0.1,0.2c0.8,2.3,1.8,4.3,3,5.9c1.4,1.9,3,3.4,4.7,4.6c1.9,1.3,4,2.3,6,3.2c1.5,0.7,3.1,1.1,4.8,1.3
          c0.4,0.1,0.8,0.1,1.2,0.1c1.1,0,2.2,0.1,3.3,0.7c0.6,0.3,1.3,1,1.7,1.6c0.3,0.5,0.8,1.5,0.9,2.2c0.2,1.2-0.5,2.8-2.2,3
          c-0.8,0.1-1.7,0-2.8-0.3c-0.9-0.3-1.9-0.7-2.9-1.3c-0.6-0.3-1.1-0.7-1.6-1.1c-0.3-0.3-0.7-0.5-1.1-0.7c-0.6-0.4-1.2-0.7-1.8-1.1
          c-0.3-0.2-0.7-0.4-1-0.6c-1.9-1.1-3.8-2.3-5.7-3.7c-1.8-1.3-3.5-2.9-5.1-4.5c-0.5-0.5-1-1-1.5-1.4c-0.7-0.7-1.5-1.3-2.2-1.9
          c-1.1-0.9-2.2-1.9-3.2-2.9c-1.6-1.6-2.5-3-2.7-4.3c0-0.3-0.1-0.5,0-0.8l0,0C202.4,102.1,203.3,101.1,204.1,100.7z M235.4,125.2
          c-0.1-0.5-0.3-1.2-0.8-2c-0.3-0.4-0.9-1-1.4-1.3c-0.9-0.5-1.9-0.6-2.9-0.6c-0.4,0-0.9,0-1.3-0.1c-1.7-0.2-3.4-0.7-5-1.4
          c-2-0.9-4.1-1.9-6.1-3.2c-1.8-1.2-3.4-2.8-4.9-4.7c-1.3-1.7-2.3-3.7-3.2-6.1l-0.1-0.2c-0.3-0.8-0.5-1.5-1-2.2
          c-0.4-0.6-1-1.2-1.9-1.7c-0.9-0.6-1.9-0.8-2.6-0.4c-0.6,0.3-1.3,1.2-1.4,2.2c0,0.2,0,0.4,0,0.6c0.2,1.2,1,2.5,2.5,4
          c1,1,2.1,2,3.2,2.9c0.7,0.7,1.5,1.3,2.2,2c0.5,0.5,1,1,1.5,1.4c1.6,1.5,3.2,3.1,5,4.4c1.8,1.3,3.8,2.5,5.6,3.6
          c0.3,0.2,0.7,0.4,1,0.6c0.6,0.4,1.2,0.7,1.8,1.1c0.4,0.2,0.7,0.5,1.1,0.8c0.5,0.4,1,0.7,1.5,1c1,0.6,1.9,1,2.8,1.2
          c0.9,0.3,1.8,0.4,2.5,0.3c1.1-0.2,1.6-1,1.7-1.8C235.5,125.5,235.5,125.3,235.4,125.2z M254.3,162.7c2.4-0.9,2.1-3.6,0.8-5.4
          c-1-1.4-2.9-2.5-4.5-3.3c-1.1-0.6-2.3-1-3.5-1.4c-4.1-1.2-8.3-1.7-12.3-3.2c-4.5-1.8-8.9-4-13.4-5.9c-1.9-0.8-3.7-1.6-5.6-2.4
          c-1.4-0.6-2.7-1.2-4.1-1.8c-4.8-2.1-9.7-4.1-14.6-6c-1.2-0.5-2.4-0.9-3.6-1.4c-2.9-1.1-5.8-2.3-8.6-3.6c-4.2-2-8.2-4.4-12.3-6.6
          c-0.9-0.4-1.7-0.9-2.6-1.3c-1.6-0.8-3.4-1.5-5.1-1.5c-0.8,0-1.6,0.2-2.2,0.6c-1,0.6-1.9,1.3-2,2.5c-0.1,0.8,0.5,2,0.9,2.8
          c1.9,3.7,7.3,4.6,11.1,5.6c6.2,1.7,12.4,3.6,18.3,6.2c1.4,0.6,2.8,1.2,4.1,1.8c1.4,0.6,2.8,1.2,4.1,1.8c4.9,2.2,9.9,4.4,14.8,6.5
          c3.3,1.4,6.7,2.8,10.1,4.1c3.8,1.5,7.8,2.9,11.4,4.9c1.1,0.7,2.3,1.3,3.4,2c1.9,1.1,3.8,2.3,5.8,3.2
          C247.5,162.3,251.4,163.8,254.3,162.7z M160.3,121.9L160.3,121.9L160.3,121.9L160.3,121.9c0.1-1.4,1.2-2.2,2.2-2.7
          c0.7-0.4,1.5-0.6,2.4-0.7c1.8,0,3.7,0.8,5.3,1.5c0.8,0.4,1.7,0.8,2.5,1.3l0.1,0.1c1.5,0.8,2.9,1.6,4.4,2.4c2.6,1.4,5.2,2.9,7.9,4.2
          c2.9,1.4,6,2.6,8.6,3.6c1.2,0.5,2.4,0.9,3.6,1.4c4.3,1.7,8.8,3.5,14.6,6c0.9,0.4,1.7,0.8,2.6,1.1l1.4,0.6c1.3,0.6,2.7,1.2,4,1.8
          l1.6,0.7c2,0.8,3.9,1.7,5.8,2.6c2.5,1.1,5,2.3,7.5,3.3c2.4,0.9,4.8,1.5,7.2,2c1.7,0.4,3.4,0.7,5,1.2c1.3,0.4,2.5,0.9,3.5,1.4
          c1.8,0.9,3.6,1.9,4.6,3.4c0.7,1,1.1,2.2,1,3.3c-0.1,1.2-0.8,2.2-2,2.6c-3,1.2-7.1-0.4-9.9-1.7c-2.1-1-4-2.1-5.8-3.2l-0.3-0.2
          c-1-0.6-2.1-1.3-3.1-1.9c-3.2-1.8-6.8-3.2-10.2-4.5l-1.1-0.4c-3.6-1.4-7.1-2.9-10.1-4.1c-4.3-1.8-9-3.9-14.8-6.5l-4.1-1.8
          c-1.4-0.6-2.7-1.2-4.1-1.8c-5.1-2.2-10.8-4.1-18.2-6.2c-0.5-0.1-1-0.3-1.6-0.4c-3.6-0.9-8-2-9.7-5.4
          C160.8,124,160.3,122.8,160.3,121.9L160.3,121.9z M254.8,157.5c-1-1.4-2.7-2.3-4.4-3.2c-1-0.5-2.1-0.9-3.4-1.3
          c-1.7-0.5-3.4-0.9-5-1.2c-2.4-0.5-4.9-1.1-7.3-2c-2.6-1-5.1-2.2-7.6-3.3c-1.9-0.9-3.9-1.8-5.8-2.6l-1.6-0.7c-1.3-0.6-2.7-1.2-4-1.8
          l-1.4-0.6c-0.9-0.4-1.7-0.8-2.6-1.1c-5.8-2.5-10.3-4.3-14.6-6c-1.2-0.5-2.4-0.9-3.6-1.4c-2.7-1-5.7-2.2-8.7-3.6
          c-2.7-1.3-5.4-2.8-8-4.2c-1.5-0.8-2.9-1.6-4.4-2.4l-0.1-0.1c-0.8-0.4-1.6-0.9-2.5-1.2c-1.5-0.7-3.3-1.5-5-1.5
          c-0.7,0-1.4,0.2-2.1,0.6c-1.1,0.6-1.8,1.2-1.9,2.2l-0.3,0l0.3,0c0,0.5,0.3,1.5,0.9,2.6c1.6,3.1,5.9,4.1,9.3,5
          c0.6,0.1,1.1,0.3,1.6,0.4c7.5,2.1,13.2,4,18.4,6.3c1.4,0.6,2.7,1.2,4.1,1.8l4.1,1.8c5.8,2.6,10.5,4.6,14.8,6.5
          c3,1.3,6.5,2.7,10.1,4.1l1.1,0.4c3.5,1.3,7,2.7,10.3,4.6c1.1,0.6,2.1,1.2,3.1,1.9l0.3,0.2c1.8,1.1,3.7,2.2,5.8,3.2
          c4.2,1.9,7.2,2.5,9.3,1.7c1.2-0.4,1.5-1.4,1.5-2C255.8,159.4,255.4,158.4,254.8,157.5z M248.6,145.2c1.6,0.3,3.7,0.4,5.1,0
          c1.8-0.6,2.9-2.3,2.5-4.1c-0.3-1.3-1.5-2.7-2.8-3.5c-3.6-2-7.7-1.4-11.5-1.5c-1.2-0.1-2.4-0.2-3.5-0.4c-4.3-0.9-8.5-2.5-12.6-4.2
          c-2.4-1-4.8-2.1-7.2-3.2c-1.7-0.8-3.4-1.6-5-2.4c-1.2-0.6-2.3-1.1-3.5-1.7c-4.1-2.1-8.2-4.4-11.6-7.5c-2.1-1.9-3.7-4.1-5.9-5.9
          c-1.2-1-2.6-2-4.1-2.6c-1.1-0.4-3-0.8-4.1-0.5c-1,0.3-2,1.2-2.3,2.1c-0.4,0.9-0.1,2.1,0.3,3.1c1.1,2.6,3.9,4,6.5,5.4
          c3.3,1.7,6.6,3.6,9.8,5.5c1.2,0.7,2.5,1.4,3.7,2.1c4.2,2.5,8.3,5,12.7,7.1c1.5,0.7,3,1.4,4.5,2c5.2,2.1,10.6,3.7,15.9,5.5
          c4.2,1.4,8.2,3.7,12.6,4.7C248.3,145.1,248.5,145.2,248.6,145.2z M181.7,109.7c0.4-1,1.5-2,2.6-2.3c1.2-0.3,3.2,0.1,4.3,0.5
          c1.6,0.6,3.2,1.8,4.2,2.6c1.2,1,2.3,2.1,3.3,3.2c0.8,0.9,1.7,1.8,2.6,2.6c3.5,3.1,7.8,5.5,11.5,7.4c1,0.5,1.9,1,2.9,1.4l0.6,0.3
          c1.7,0.8,3.3,1.6,5,2.4c2.6,1.2,4.9,2.2,7.2,3.2c4,1.7,8.3,3.3,12.6,4.2c1.2,0.2,2.4,0.4,3.5,0.4c0.9,0,1.8,0,2.7,0
          c3,0,6.2,0,9,1.6c1.5,0.8,2.7,2.3,3,3.7c0.4,1.9-0.7,3.8-2.7,4.5c-1.4,0.5-3.7,0.4-5.3,0.1c-0.1,0-0.3,0-0.4-0.1l-0.1,0
          c-2.7-0.6-5.3-1.7-7.8-2.8c-1.6-0.7-3.2-1.4-4.9-1.9c-1.5-0.5-3-1-4.5-1.5c-3.8-1.2-7.7-2.5-11.4-4c-1.6-0.6-3.1-1.3-4.6-2
          c-3.9-1.8-7.7-4.1-11.3-6.2l-1.4-0.8c-1.2-0.7-2.4-1.4-3.7-2.1l-1.9-1.1c-2.5-1.5-5.2-3-7.8-4.3c-2.5-1.3-5.5-2.8-6.7-5.5
          c-0.3-0.7-0.6-1.6-0.5-2.6C181.6,110.2,181.6,109.9,181.7,109.7z M255.9,141.2c-0.3-1.2-1.4-2.5-2.7-3.3c-2.7-1.5-5.7-1.5-8.6-1.5
          c-0.9,0-1.8,0-2.7,0c-1.1-0.1-2.3-0.2-3.6-0.5c-4.4-0.9-8.7-2.5-12.7-4.2c-2.3-0.9-4.6-2-7.2-3.2c-1.7-0.8-3.4-1.6-5-2.4l-0.6-0.3
          c-1-0.5-1.9-0.9-2.9-1.4c-3.8-1.9-8.1-4.3-11.7-7.5c-0.9-0.8-1.8-1.8-2.6-2.7c-1-1.1-2-2.2-3.2-3.2c-1-0.8-2.5-1.9-4-2.5
          c-1-0.4-2.9-0.8-3.9-0.5c-0.8,0.2-1.8,1.1-2.1,1.9c-0.3,0.7-0.1,1.7,0.3,2.9c1,2.5,3.8,3.9,6.3,5.2c2.7,1.4,5.3,2.9,7.9,4.4
          l1.9,1.1c1.2,0.7,2.5,1.4,3.7,2.2l1.4,0.8c3.6,2.1,7.4,4.4,11.3,6.2c1.4,0.7,2.9,1.3,4.5,1.9c3.8,1.5,7.6,2.8,11.4,4
          c1.5,0.5,3,1,4.5,1.5c1.7,0.6,3.3,1.3,4.9,2c2.5,1.1,5,2.2,7.6,2.7l0.1,0c0.1,0,0.2,0,0.4,0.1c1.4,0.3,3.6,0.4,4.9,0
          c1.4-0.5,2.2-1.5,2.3-2.8C256,141.7,255.9,141.5,255.9,141.2z M223.1,164.2c1,0.5,2.4,1.1,3.4,1.2c1.3,0.1,2.4-0.6,2.5-1.8
          c0.1-0.9-0.5-2.2-1.2-3.1c-2.1-2.4-5.3-3.1-8-3.9c-0.8-0.3-1.7-0.6-2.5-0.9c-3.1-1.2-6.1-2.7-9.1-4c-1.8-0.8-3.6-1.5-5.5-2.1
          c-1.3-0.5-2.6-0.9-4-1.2c-0.9-0.3-1.9-0.5-2.8-0.7c-3.4-0.8-6.8-1.5-10-2.6c-2-0.7-3.8-1.7-5.9-2c-1.2-0.2-2.5-0.3-3.8,0
          c-0.9,0.2-2.4,0.9-3,1.6c-0.5,0.6-0.8,1.8-0.8,2.5c0.1,0.8,0.7,1.4,1.3,1.9c1.6,1.2,3.8,0.9,5.9,0.7c2.7-0.2,5.4,0,8,0.4
          c1,0.1,2,0.3,3,0.5c3.3,0.7,6.6,1.8,9.8,2.9c1.1,0.3,2.2,0.7,3.3,1c3.8,1.2,7.5,2.4,11.1,4.2c2.8,1.4,5.2,3.6,8,5.1
          C222.9,164.1,223,164.1,223.1,164.2z M172.9,144.5c0.7-0.8,2.2-1.5,3.1-1.7c1.1-0.3,2.4-0.3,3.9,0c1.5,0.2,2.8,0.8,4.1,1.3
          c0.6,0.3,1.3,0.5,1.9,0.7c2.3,0.8,4.8,1.4,7.2,1.9c0.9,0.2,1.9,0.4,2.8,0.7c1,0.2,1.9,0.5,2.8,0.7c1.3,0.4,2.7,0.8,4,1.3
          c1.8,0.6,3.6,1.3,5.5,2.1c1.3,0.6,2.7,1.2,4,1.7c1.7,0.8,3.4,1.5,5.2,2.2c0.8,0.3,1.7,0.6,2.5,0.9l0.8,0.2c2.5,0.7,5.3,1.5,7.3,3.7
          c0.9,1,1.4,2.3,1.3,3.3l0,0c-0.1,1.4-1.3,2.2-2.9,2.1c-1.2-0.1-2.9-0.9-3.5-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-0.7-2.5-1.6-3.6-2.4
          c-1.4-1-2.8-2-4.3-2.7c-3.6-1.8-7.4-3.1-11-4.2l-1.6-0.5c-0.6-0.2-1.1-0.3-1.7-0.5c-0.6-0.2-1.2-0.4-1.8-0.6
          c-2.6-0.8-5.3-1.7-8-2.3c-1-0.2-1.9-0.4-2.9-0.5c-3-0.4-5.6-0.6-7.9-0.4l-0.6,0c-2,0.1-4,0.3-5.5-0.8c-0.6-0.5-1.3-1.2-1.5-2.1
          c0-0.2,0-0.3,0-0.5C172.1,146.1,172.4,145.1,172.9,144.5z M227.5,160.8c-1.9-2.1-4.5-2.8-7-3.5l-0.8-0.2c-0.8-0.3-1.7-0.6-2.6-0.9
          c-1.7-0.7-3.5-1.5-5.2-2.2c-1.3-0.6-2.6-1.2-4-1.7c-1.9-0.8-3.7-1.5-5.5-2.1c-1.3-0.5-2.6-0.9-3.9-1.2c-0.9-0.3-1.8-0.5-2.8-0.7
          c-0.9-0.2-1.9-0.5-2.8-0.7c-2.4-0.6-4.9-1.1-7.2-1.9c-0.7-0.2-1.3-0.5-1.9-0.8c-1.3-0.5-2.5-1-3.9-1.3c-1.4-0.2-2.6-0.2-3.7,0
          c-0.8,0.2-2.2,0.8-2.8,1.5c-0.4,0.5-0.8,1.6-0.7,2.3c0.1,0.7,0.7,1.3,1.2,1.6c1.3,1,3.2,0.8,5.1,0.7l0.6,0c2.4-0.1,5,0,8.1,0.4
          c1,0.1,2,0.3,3,0.5c2.7,0.6,5.4,1.5,8,2.3c0.6,0.2,1.2,0.4,1.8,0.6c0.6,0.2,1.1,0.4,1.7,0.5l1.6,0.5c3.6,1.1,7.5,2.4,11.1,4.2
          c1.6,0.8,3,1.8,4.4,2.8c1.2,0.8,2.4,1.7,3.6,2.4c0.1,0.1,0.2,0.1,0.3,0.2c1.3,0.7,2.5,1.1,3.2,1.2c1.2,0.1,2-0.5,2.1-1.5
          C228.7,162.7,228.2,161.6,227.5,160.8z M205.2,165.2c0.6,0.2,1.3,0.4,2,0.3c0.9-0.2,1.7-1,2-1.9c0.2-0.7,0-1.6-0.4-2
          c-1.2-1.3-3.3-1.1-5-1.1c-0.5,0-1-0.1-1.5-0.2c-1.9-0.3-3.6-0.9-5.5-1.3c-1.1-0.2-2.1-0.5-3.2-0.7c-0.8-0.1-1.5-0.2-2.3-0.3
          c-0.5-0.1-1.1-0.1-1.6-0.2c-2-0.2-3.9-0.3-5.8-0.6c-1.2-0.2-2.3-0.5-3.6-0.5c-0.7,0-1.4,0-2.1,0.2c-0.5,0.1-1.1,0.3-1.3,0.6
          c-0.2,0.2-0.1,0.5,0.1,0.7c0.2,0.2,0.6,0.4,1.1,0.6c1,0.4,2.2,0.3,3.2,0.3c1.4,0,2.8,0,4.2,0.2c0.5,0.1,1.1,0.1,1.6,0.2
          c1.8,0.3,3.7,0.7,5.5,1.2c0.6,0.1,1.2,0.3,1.8,0.4c2.1,0.5,4.2,0.9,6.2,1.7c1.6,0.6,2.9,1.8,4.4,2.5
          C205.1,165.1,205.2,165.2,205.2,165.2z M176.6,157.2c0.3-0.4,1.5-0.7,1.5-0.7c0.5-0.1,1.3-0.2,2.2-0.2c0.9,0,1.8,0.2,2.7,0.3
          c0.3,0.1,0.6,0.1,0.9,0.2c1.5,0.2,3.1,0.4,4.6,0.5l1.4,0.1c0.5,0,0.9,0.1,1.4,0.2c0.9,0.1,1.6,0.2,2.3,0.3c1.1,0.2,2.2,0.4,3.2,0.7
          c0.7,0.2,1.3,0.3,2,0.5c1.1,0.3,2.3,0.6,3.5,0.8c0.5,0.1,1,0.2,1.5,0.2c0.3,0,0.6,0,0.9,0c1.5,0,3.2,0.1,4.3,1.2
          c0.5,0.6,0.7,1.6,0.5,2.4c-0.3,1-1.2,1.9-2.3,2.1c-0.8,0.2-1.7-0.1-2.3-0.4l-0.1,0c-0.7-0.3-1.4-0.8-2-1.2c-0.8-0.5-1.5-1-2.4-1.3
          c-1.9-0.7-3.9-1.2-5.9-1.6l-3.1-0.7c-1.5-0.3-3-0.7-4.5-0.9c-0.5-0.1-1.1-0.1-1.6-0.2c-1.6-0.2-3-0.2-4.2-0.2
          c-1.1,0-2.2,0.1-3.3-0.3c-0.6-0.2-1-0.5-1.2-0.7c-0.2-0.2-0.3-0.5-0.2-0.7C176.5,157.4,176.5,157.3,176.6,157.2z M208.5,161.8
          c-0.9-0.9-2.4-1-3.9-1c-0.3,0-0.6,0-0.9,0c-0.5,0-1-0.1-1.6-0.2c-1.2-0.2-2.4-0.5-3.5-0.8c-0.7-0.2-1.3-0.3-2-0.5
          c-1.1-0.3-2.2-0.5-3.2-0.7c-0.7-0.1-1.5-0.2-2.3-0.3c-0.5-0.1-0.9-0.1-1.4-0.2l-1.4-0.1c-1.5-0.1-3.1-0.3-4.6-0.5
          c-0.3,0-0.6-0.1-0.9-0.2c-0.8-0.2-1.7-0.3-2.6-0.3c-0.8,0-1.5,0.1-2,0.2c-0.5,0.1-1,0.3-1.1,0.4c0,0,0,0.2,0.1,0.3
          c0.1,0.1,0.3,0.3,0.9,0.5c1,0.4,2,0.3,3.1,0.3c1.3,0,2.7,0,4.3,0.2c0.6,0.1,1.1,0.1,1.7,0.2c1.6,0.2,3.1,0.6,4.6,0.9l3.1,0.7
          c2,0.4,4,0.9,6,1.6c0.9,0.3,1.7,0.9,2.5,1.4c0.6,0.4,1.2,0.8,1.9,1.1c0.1,0,0.1,0,0.2,0.1c0.7,0.3,1.3,0.4,1.8,0.3
          c0.8-0.2,1.5-0.9,1.7-1.7c0-0.1,0.1-0.3,0.1-0.4C209,162.6,208.8,162.1,208.5,161.8z M192.7,171.1c-1.1-0.6-2.5-2.3-1.1-3.1
          c1.6-0.9,5.1,0.9,4.5,2.8C195.6,172.2,193.7,171.8,192.7,171.1z M191.4,167.7c1-0.6,2.6-0.2,3.6,0.4c1.2,0.7,1.8,1.8,1.4,2.8
          c-0.2,0.5-0.5,0.8-1,1c-0.9,0.3-2.1,0-2.9-0.5c-0.8-0.5-1.9-1.6-1.9-2.6C190.6,168.5,190.8,168.1,191.4,167.7z M194.6,168.7
          c-1-0.6-2.2-0.8-2.9-0.4c-0.3,0.2-0.4,0.3-0.4,0.6c0,0.6,0.8,1.5,1.5,1.9c0.7,0.4,1.7,0.7,2.3,0.4c0.3-0.1,0.5-0.3,0.6-0.6
          c0-0.1,0-0.2,0.1-0.3C195.8,169.9,195.4,169.2,194.6,168.7z"
        />
        <path
          className="protein-nav__cell_dark"
          d="M194.6,172.6c-0.9,0-1.8-0.3-2.4-0.7c-0.8-0.5-2.2-1.7-2.1-3.1c0-0.4,0.2-1,1-1.5l0,0c1.2-0.7,2.9-0.3,4.1,0.4
          c1.4,0.9,2,2.2,1.6,3.4c-0.2,0.6-0.7,1.1-1.3,1.3C195.3,172.5,194.9,172.6,194.6,172.6z M192.6,168.6c-0.3,0-0.5,0-0.6,0.1
          c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.3,0.5,1,1.3,1.5c0.6,0.4,1.5,0.6,1.9,0.4c0.1,0,0.2-0.1,0.3-0.3c0,0,0-0.1,0-0.1
          c0-0.4-0.4-0.9-1-1.2C193.7,168.8,193.1,168.6,192.6,168.6z M206.8,166.4c-0.7,0-1.4-0.2-2-0.5l-0.1,0c-0.7-0.3-1.4-0.8-2.1-1.2
          c-0.7-0.5-1.5-1-2.3-1.3c-1.9-0.7-3.9-1.1-5.8-1.6l-3.3-0.7c-1.4-0.3-2.9-0.7-4.3-0.9c-0.5-0.1-1-0.1-1.6-0.2
          c-1.6-0.1-2.9-0.2-4.2-0.2c-1.2,0-2.4,0.1-3.5-0.4c-0.7-0.3-1.1-0.5-1.4-0.8c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.3,0.1-0.5,0.3-0.7
          c0.4-0.5,1.6-0.8,1.8-0.8c0.6-0.1,1.3-0.2,2.3-0.2c0.9,0,1.8,0.2,2.7,0.3l1,0.2c1.5,0.2,3,0.4,4.4,0.5l1.7,0.2
          c0.4,0,0.8,0.1,1.2,0.1c0.9,0.1,1.6,0.2,2.4,0.3c1,0.2,2.1,0.4,3.3,0.7c0.7,0.2,1.3,0.3,2,0.5c1.1,0.3,2.3,0.6,3.4,0.8
          c0.5,0.1,1,0.2,1.4,0.2c0.3,0,0.6,0,0.8,0c1.6,0,3.5,0.1,4.7,1.4c0.7,0.7,0.9,1.9,0.6,2.9c-0.4,1.2-1.4,2.2-2.6,2.5
          C207.2,166.4,207,166.4,206.8,166.4z M205.4,164.3C205.4,164.3,205.4,164.4,205.4,164.3c0.7,0.3,1.3,0.4,1.7,0.3
          c0.6-0.1,1.2-0.7,1.4-1.3c0-0.1,0-0.2,0.1-0.3c0-0.4-0.1-0.7-0.3-0.9l0,0c-0.8-0.8-2.2-0.8-3.5-0.9l-0.4,0c-0.2,0-0.4,0-0.5,0
          c-0.5,0-1.1-0.1-1.7-0.2c-1.2-0.2-2.3-0.5-3.4-0.8l-0.1,0c-0.7-0.2-1.3-0.3-2-0.5c-1.1-0.3-2.2-0.5-3.2-0.7
          c-0.7-0.1-1.5-0.2-2.3-0.3c-0.5-0.1-0.9-0.1-1.4-0.2l-1.9-0.2c-1.4-0.1-2.8-0.3-4.2-0.5c-0.2,0-0.4-0.1-0.6-0.1l-0.3-0.1
          c-0.9-0.2-1.7-0.3-2.5-0.3c-0.8,0-1.4,0-1.9,0.2c-0.2,0-0.3,0.1-0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.9,0.3,1.9,0.3,2.9,0.3
          c1.3,0,2.7,0,4.3,0.2c0.6,0.1,1.1,0.1,1.7,0.2c1.5,0.2,3,0.6,4.6,1l3.1,0.7c1.9,0.4,4,0.9,6,1.6c1,0.4,1.8,0.9,2.6,1.5l0.1,0.1
          C204.2,163.7,204.8,164.1,205.4,164.3C205.4,164.3,205.4,164.3,205.4,164.3z M226.7,166.2c-0.1,0-0.2,0-0.3,0
          c-1.3-0.1-2.9-0.8-3.7-1.3c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-0.7-2.6-1.6-3.7-2.4c-1.3-0.9-2.7-1.9-4.2-2.7c-3.6-1.8-7.6-3.1-11-4.1
          l-5.1-1.6c-2.6-0.8-5.3-1.7-7.9-2.3c-0.9-0.2-1.9-0.4-2.9-0.5c-3-0.4-5.5-0.5-7.8-0.4l-0.6,0c-2,0.1-4.2,0.3-5.9-0.9
          c-0.7-0.5-1.5-1.4-1.7-2.5c0-0.2,0-0.4,0-0.6c0-0.7,0.3-1.8,0.9-2.5c0.8-1,2.5-1.7,3.4-1.9c1.2-0.3,2.5-0.3,4.1,0
          c1.5,0.2,2.9,0.8,4.2,1.3c0.6,0.3,1.2,0.5,1.9,0.7c2.3,0.8,4.9,1.4,7.1,1.9c0.9,0.2,1.9,0.4,2.8,0.7c1,0.2,1.9,0.5,2.8,0.7
          c1.4,0.4,2.7,0.8,4,1.3c1.9,0.7,3.7,1.3,5.5,2.1c1.1,0.5,2.2,0.9,3.3,1.4l0.7,0.3c1.7,0.8,3.4,1.6,5.1,2.2c0.9,0.3,1.7,0.6,2.4,0.9
          l0.8,0.2c2.6,0.7,5.5,1.5,7.6,3.9c1,1.1,1.5,2.6,1.5,3.7C229.7,165.2,228.4,166.2,226.7,166.2z M181.6,148.9c1.9,0,3.9,0.2,6.2,0.5
          c0.9,0.1,2,0.3,3,0.6c2.8,0.6,5.5,1.5,8.1,2.3l5.1,1.6c3.6,1.1,7.5,2.4,11.2,4.2c1.6,0.8,3.1,1.8,4.5,2.8c1.1,0.8,2.3,1.6,3.5,2.3
          c0.1,0.1,0.2,0.1,0.3,0.1c1.2,0.6,2.3,1.1,3,1.1c0.9,0.1,1.5-0.3,1.6-1.1c0-0.6-0.4-1.7-1-2.4l0,0c-1.8-2-4.3-2.7-6.7-3.4l-0.8-0.2
          c-0.9-0.3-1.7-0.6-2.6-0.9c-1.7-0.7-3.3-1.4-5-2.1l-0.7-0.3c-1.2-0.5-2.3-1-3.5-1.5c-1.9-0.8-3.7-1.5-5.4-2.1
          c-1.3-0.5-2.6-0.9-3.9-1.2c-0.9-0.2-1.7-0.5-2.7-0.7l-0.1,0c-0.9-0.2-1.9-0.5-2.8-0.7l-0.2,0c-2.3-0.5-4.8-1.1-7.1-1.9
          c-0.6-0.2-1.2-0.4-1.7-0.7l-0.2-0.1c-1.3-0.5-2.5-1-3.8-1.2c-1.4-0.2-2.5-0.2-3.5,0c-0.7,0.2-2.1,0.8-2.5,1.3
          c-0.3,0.4-0.6,1.4-0.6,1.9c0.1,0.4,0.4,0.9,1,1.3c1.2,0.9,3,0.7,4.7,0.6l0.6,0C180.3,149,181,148.9,181.6,148.9z M252,163.9
          c-2.7,0-5.6-1.2-7.7-2.2c-2.1-1-4-2.1-5.9-3.2l-0.3-0.2c-1-0.6-2.1-1.3-3.1-1.8c-3.2-1.8-6.7-3.2-10.1-4.5l-1.1-0.4
          c-3.8-1.4-7.4-3-10.1-4.1c-4.2-1.8-8.9-3.8-14.8-6.5l-4.1-1.8c-1.4-0.6-2.7-1.2-4.1-1.8c-5.1-2.2-10.7-4.1-18.2-6.2
          c-0.5-0.1-1-0.3-1.6-0.4c-3.7-0.9-8.2-2.1-10.1-5.6c-0.4-0.8-0.9-2-1-2.9l0,0l0-0.3c0.1-1.8,1.7-2.7,2.5-3.2
          c0.8-0.5,1.7-0.7,2.6-0.7c2,0,3.9,0.8,5.5,1.6c0.8,0.4,1.7,0.8,2.5,1.3l0.1,0.1c1.5,0.8,2.9,1.6,4.4,2.4c2.4,1.3,5.2,2.9,7.9,4.2
          c2.8,1.4,5.8,2.5,8.6,3.6l0.7,0.3c1,0.4,1.9,0.7,2.9,1.1c4.4,1.7,8.9,3.6,14.6,6l9.7,4.2c1.8,0.8,3.7,1.6,5.5,2.4l0.4,0.2
          c2.5,1.2,5,2.3,7.5,3.3c2.3,0.9,4.8,1.5,7.2,2c1.6,0.3,3.4,0.7,5.1,1.2c1.4,0.4,2.6,0.9,3.6,1.4c1.8,0.9,3.7,2,4.8,3.6
          c0.8,1.1,1.2,2.5,1.1,3.6c-0.1,1.4-0.9,2.5-2.3,3.1C253.8,163.8,252.9,163.9,252,163.9z M161.5,122L161.5,122
          c0,0.4,0.2,1.1,0.8,2.3c1.5,2.9,5.6,3.9,9,4.8c0.6,0.1,1.1,0.3,1.6,0.4c7.6,2.1,13.2,4,18.4,6.3c1.4,0.6,2.7,1.2,4.1,1.8l4.1,1.8
          c5.4,2.4,10.1,4.5,14.8,6.5c3.1,1.3,6.5,2.8,10.1,4.1l1.2,0.5c3.4,1.3,7,2.7,10.2,4.5c1.1,0.6,2.1,1.3,3.2,1.9l0.3,0.2
          c1.8,1.1,3.7,2.2,5.7,3.1c4.1,1.9,7,2.4,8.9,1.7c0.8-0.3,1.2-0.8,1.2-1.6c0.1-0.8-0.3-1.7-0.8-2.6l0,0c-0.9-1.3-2.5-2.2-4.2-3
          c-1-0.5-2.1-0.9-3.4-1.3c-1.6-0.5-3.3-0.9-5-1.2l-0.1,0c-2.4-0.5-4.9-1.1-7.3-2c-2.6-1-5.1-2.2-7.6-3.3c-1.8-0.9-3.8-1.8-5.8-2.6
          l-1.6-0.7c-0.8-0.4-1.7-0.7-2.5-1.1l-3-1.3c-0.9-0.4-1.7-0.8-2.6-1.1c-5.7-2.4-10.1-4.3-14.5-6l-3.6-1.4c-2.7-1-5.7-2.2-8.7-3.6
          c-2.8-1.3-5.6-2.9-8-4.2l-0.3-0.2c-1.3-0.7-2.7-1.5-4-2.2l-0.1-0.1c-0.8-0.4-1.6-0.8-2.4-1.2c-1.5-0.7-3.2-1.5-4.8-1.4
          c-0.7,0-1.3,0.2-1.8,0.5C162,120.9,161.6,121.3,161.5,122z M251.4,146.3c-1,0-2.1-0.1-2.9-0.3c-0.1,0-0.3,0-0.4-0.1l-0.1,0
          c-2.8-0.6-5.5-1.8-7.8-2.8c-1.6-0.7-3.2-1.4-4.8-1.9c-1.5-0.5-3-1-4.5-1.5c-3.8-1.2-7.7-2.5-11.5-4c-1.5-0.6-3.1-1.3-4.6-2
          c-3.9-1.8-7.6-4-11.2-6.2l-1.6-0.9c-1.2-0.7-2.4-1.4-3.7-2.1l-1.9-1.1c-2.4-1.4-5.1-2.9-7.8-4.3c-2.5-1.3-5.7-2.9-6.9-5.8
          c-0.3-0.7-0.6-1.8-0.6-2.8c0-0.3,0.1-0.7,0.2-1l0,0c0.4-1.1,1.7-2.2,2.9-2.6c1.4-0.4,3.6,0.2,4.6,0.5c1.7,0.7,3.3,1.9,4.4,2.7
          c1.3,1,2.4,2.2,3.4,3.3c0.9,1,1.7,1.8,2.5,2.6c3.4,3.1,7.7,5.5,11.4,7.3c1,0.5,1.9,1,2.9,1.4l0.6,0.3c1.5,0.7,3,1.4,4.5,2.1
          l0.5,0.2c2.5,1.2,4.8,2.2,7.1,3.2c3.9,1.6,8.2,3.3,12.5,4.1c1.2,0.2,2.3,0.4,3.4,0.4c0.8,0,1.7,0,2.6,0c3.1,0,6.3,0,9.2,1.6
          c1.6,0.9,2.9,2.5,3.3,4.1c0.5,2.1-0.8,4.3-3,5C253.3,146.2,252.3,146.3,251.4,146.3z M185.3,108.5c-0.2,0-0.5,0-0.6,0.1
          c-0.7,0.2-1.5,1-1.8,1.6c-0.2,0.5-0.1,1.5,0.3,2.5c1,2.3,3.7,3.7,6.1,4.9c2.7,1.4,5.3,2.9,7.9,4.4l1.9,1.1c1.2,0.7,2.5,1.4,3.7,2.2
          l1.6,1c3.5,2.1,7.2,4.3,11,6.1c1.4,0.7,2.9,1.3,4.5,1.9c3.7,1.5,7.6,2.7,11.3,4c1.6,0.5,3.1,1,4.6,1.5c1.6,0.5,3.2,1.2,4.8,1.9
          l0.2,0.1c2.4,1.1,5,2.1,7.6,2.7l0.1,0c0.1,0,0.2,0,0.2,0.1l0.1,0c1.5,0.3,3.5,0.4,4.6,0c1.2-0.4,1.9-1.3,2-2.4c0-0.2,0-0.5,0-0.7
          c-0.2-1.1-1.3-2.3-2.4-3c-2.5-1.4-5.5-1.4-8.4-1.4c-0.9,0-1.8,0-2.7,0c-1.2-0.1-2.4-0.2-3.7-0.5c-4.4-0.9-8.7-2.6-12.8-4.2
          c-2.3-0.9-4.6-2-7.2-3.2c-1.7-0.8-3.4-1.6-5-2.4l-0.9-0.4c-0.9-0.4-1.8-0.9-2.6-1.3c-3.8-1.9-8.2-4.4-11.8-7.6
          c-0.9-0.8-1.7-1.7-2.6-2.7c-1-1.1-2-2.2-3.2-3.1c-1.5-1.2-2.8-2-3.9-2.4C187.3,108.7,186.1,108.5,185.3,108.5z M233.2,128.7
          c-0.7,0-1.4-0.1-2.2-0.3c-0.9-0.3-1.9-0.7-3-1.3c-0.5-0.3-1-0.7-1.5-1l-0.3-0.2c-0.3-0.2-0.5-0.4-0.8-0.6c-0.5-0.3-1-0.6-1.5-0.9
          l-0.3-0.2c-0.3-0.2-0.7-0.4-1-0.6c-1.9-1.1-3.9-2.3-5.7-3.7c-1.8-1.3-3.5-2.9-5.1-4.4c-0.6-0.5-1.1-1-1.6-1.5
          c-0.7-0.7-1.4-1.3-2.2-1.9l-0.2-0.2c-1-0.9-2-1.8-3-2.8c-1.7-1.7-2.6-3.2-2.9-4.6c-0.1-0.3-0.1-0.6,0-0.9c0.1-1.6,1.1-2.8,2-3.2
          l0,0c1.1-0.6,2.5-0.4,3.8,0.4c1,0.6,1.7,1.3,2.2,2.1c0.6,0.8,0.9,1.7,1.1,2.5l0.1,0.2c0.8,2.3,1.8,4.2,3,5.8
          c1.4,1.8,2.9,3.3,4.6,4.5c1.9,1.3,4.1,2.3,5.9,3.1c1.5,0.7,3,1.1,4.6,1.3c0.4,0.1,0.7,0.1,1.1,0.1c1.2,0.1,2.4,0.1,3.6,0.8
          c0.6,0.4,1.4,1.1,1.8,1.7c0.4,0.7,0.8,1.6,0.9,2.4c0.2,1.5-0.8,3.3-2.7,3.6C233.7,128.7,233.5,128.7,233.2,128.7z M205.3,101.6
          c-0.2,0-0.5,0-0.7,0.1c-0.5,0.3-1.1,1-1.1,1.8c0,0.2,0,0.3,0,0.5c0.2,1.1,1,2.3,2.4,3.7c1,1,2,1.9,3.2,2.9c0.7,0.7,1.5,1.3,2.2,2
          c0.4,0.3,0.7,0.7,1.1,1l0.4,0.4c1.6,1.5,3.2,3.1,5,4.4c1.8,1.4,3.8,2.6,5.6,3.6c0.3,0.2,0.7,0.4,1,0.6c0.6,0.4,1.2,0.7,1.8,1.1
          c0.4,0.2,0.7,0.5,1.1,0.7l0.3,0.2c0.4,0.3,0.8,0.6,1.3,0.8c1,0.5,1.9,0.9,2.7,1.2c0.9,0.3,1.6,0.3,2.2,0.2c0.8-0.1,1.2-0.8,1.3-1.4
          c0-0.1,0-0.2,0-0.2l0-0.1c0-0.4-0.3-1.1-0.7-1.8c-0.2-0.4-0.8-0.9-1.2-1.1c-0.8-0.4-1.7-0.5-2.7-0.5c-0.4,0-0.9,0-1.3-0.1
          c-1.8-0.2-3.5-0.7-5.1-1.4c-2-0.9-4.1-1.9-6.1-3.3c-1.8-1.2-3.5-2.9-5-4.8c-1.3-1.7-2.4-3.8-3.2-6.2l-0.1-0.1
          c-0.3-0.8-0.5-1.5-0.9-2.1c-0.4-0.6-0.9-1.1-1.7-1.6C206.2,101.8,205.7,101.6,205.3,101.6z"
        />
      </g>
      <g
        data-localization="mitochondrion"
        onBlur={handleGraphicMouseOut}
        onFocus={handleGraphicMouseOver}
        onMouseOut={handleGraphicMouseOut}
        onMouseOver={handleGraphicMouseOver}
      >
        <path
          className="protein-nav__cell_dark"
          d="M279.8,185.4c6.9-1.3,13.9,5.1,15.7,14.2l5.8,29.8c1.8,9.2-2.4,17.7-9.2,19.1c-6.9,1.3-13.9-5.1-15.7-14.2
          l-5.8-29.8C268.8,195.3,273,186.7,279.8,185.4z"
        />
        <path
          className="protein-nav__cell_light"
          d="M284.6,240c-1.3-0.9-2.5-2-3.3-3.3c-0.4-0.6-0.7-1.3-0.7-2c-0.1-1.8,1.1-1.4,2.7-1.7c0.8-0.1,1.4-0.5,2.1-0.6
          c0.3-0.1,0.9-0.1,1.1-0.4c0.1-0.2-0.2-1.8-0.4-1.9c-1-0.5-2.3,0.3-3.2,0.7c-0.5,0.2-1.7,0.6-2.3,0.5c-1.3-0.2-1.3-0.9-1.5-2
          c-0.2-1-0.4-2-0.6-3c-0.1-1-0.5-2.1,0.4-2.9c0.5-0.4,1.8-0.5,2.4-0.5c0.5,0,1,0.1,1.5,0.1c0.5,0,1.2-0.1,1.7-0.4
          c0.5-0.4,0.1-2.7-0.4-2.8c-0.8-0.3-1.9,0.3-2.7,0.5c-0.9,0.2-2,1-3,0.7c-1.1-0.4-1.1-1-1.3-2c-0.2-0.8-0.5-1.5-0.7-2.3
          c-0.3-0.9-0.7-2.2-0.1-3c0.5-0.6,1.9,0,2.6,0c0.6,0,1.4,0.1,2,0c0.4-0.1,2.3-1.2,1.5-2c-0.9-1.1-2.4-1-3.4-0.6
          c-0.6,0.2-1.1,0.4-1.8,0.4c-1.3-0.2-1.6-0.5-1.9-1.5c-0.3-1.1-1-2.6-0.1-3.6c0.8-0.8,1.9-0.8,2.9-1c0.8-0.1,2-0.2,2.6-0.8
          c0.2-0.2,0-1.2-0.2-1.4c-0.3-0.4-0.8-0.4-1.2-0.4c-1.2,0.1-2.4,1.1-3.6,1.3c-1.3,0.2-1.8-1-1.9-2c0-0.7,0.1-1.5,0.2-2.2
          c0.1-1,0.1-2.3,0.7-3.1c0.3-0.4,0.8-0.6,1.3-0.6c0.7,0,1.7,0.9,2.4,0.5c0.5-0.2,0.5-1.5,0.4-1.9c-0.1-0.6-0.3-1.4,0-2
          c0.6-1.1,2.2-1.3,3.3-1.1c1.6,0.2,3.1,0.8,4.5,1.4c1.5,0.7,3.2,1,4.2,2.5c0.7,1-0.4,2-1.2,2.2c-1.1,0.3-2.3,0.2-3.4,0.2
          c-0.7,0-1.9-0.1-2.4,0.5c-0.3,0.3-0.2,1.3,0.2,1.5c0.4,0.3,1.2,1.2,1.7,1.1c2.7-0.6,5.9-2.4,6.9,0.6c0.4,1.1,1,2.5,0.6,3.7
          c-0.5,1.5-1.8,1-3.1,1c-0.9,0-2.2,0.2-3,0.5c-0.3,0.1-0.7,0.4-0.7,0.8c0.2,1.4,2.4,1.6,3.2,1.4c1.1-0.2,2.7-0.8,3.7-0.1
          c0.5,0.3,0.3,0.4,0.5,0.9c0.4,0.9,1.1,2.4,0,3c-0.9,0.5-1.8,0.3-2.7,0.3c-0.6,0-1.1,0.1-1.7,0.2c-0.4,0.1-0.9,0-1.3,0.3
          c-0.2,0.1-0.3,0.7-0.2,0.9c0.1,0.3,0.5,0.8,0.8,0.9c1.3,0.2,2.3,0.2,3.5-0.1c0.8-0.2,2.2-0.4,2.7,0.2c0.7,0.7,0.5,1.2,0.6,2.1
          c0.1,1.1,0.4,1.9-0.2,3c-0.5,0.8-1.4,0.8-2.2,0.8c-0.9,0-1.4,0.1-2.3,0.2c-0.4,0-1.7,0.7-1.8,1.3c-0.3,1.2,4.1,1.5,4.7,1.4
          c0.8-0.1,2.4-0.8,3-0.2c0.7,0.8,1.2,2.3,0.7,3.3c-0.7,1.3-1.9,0.5-3.2,0.6c-0.7,0-1.8-0.4-2.5-0.4c-0.4,0-1.8,1.5-1.3,2.2
          c0.4,0.5,1.5,1.3,2,1.2c1.2-0.2,2.1-0.8,3.4-0.9c0.7-0.1,2.1-0.3,2.4,0.3c0.6,1.1,0.2,2.4-0.2,3.4c-0.5,1.3-1.6,1.9-2.9,1.4
          c-0.6-0.2-1.2-0.6-1.8-0.8c-0.4-0.1-0.9-0.4-1.3-0.5c-0.4-0.1-1.7,0.5-1.5,1.2c0.1,0.3,1.2,1.1,1.5,1.2c0.5,0.2,1.4,0.1,1.9,0.3
          c0.8,0.2,2,0.1,1.6,1.1c-0.2,0.6-0.8,1.5-1.3,1.9c-1.2,1-2.7,1.9-4.4,1.9C288.2,241.6,286.2,240.9,284.6,240z"
        />
      </g>
    </svg>
    <div className="protein-nav__cell-description">{getHighlightName(highlighted)}</div>
  </div>
);

InfectedCell.propTypes = {
  handleGraphicMouseOut: PropTypes.func.isRequired,
  handleGraphicMouseOver: PropTypes.func.isRequired,
  highlighted: PropTypes.shape({
    cell: PropTypes.string,
    protein: PropTypes.string,
  }).isRequired,
};

export default InfectedCell;
